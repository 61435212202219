import { setDocumentStyle } from '@/logic/dom/helpers';
import { debounce } from 'lodash-es';

export default {
  data() {
    return {
      isTouchingList: false
    };
  },
  mounted() {
    const mobilePortSelector = this.$refs.mobilePortSelector;
    const mobilePortSelectorList = this.$refs.mobilePortSelectorList;

    if (!mobilePortSelector || !mobilePortSelectorList) return;

    mobilePortSelector.addEventListener('pointerdown', event => this.onPointerDown(event));
    mobilePortSelectorList.addEventListener('scroll', event => this.onScroll(event));
    mobilePortSelectorList.addEventListener('pointerdown', this.onPointerDownList);
    window.addEventListener('resize', this.calculatePortsListTop);
    this.calculatePortsListTop();
  },
  beforeUnmount() {
    this.$refs.mobilePortSelector.removeEventListener('pointerdown', this.onPointerDown);
    this.$refs.mobilePortSelectorList.removeEventListener('scroll', this.onScroll);
    this.$refs.mobilePortSelectorList.removeEventListener('pointerdown', this.onPointerDownList);
    window.removeEventListener('resize', this.calculatePortsListTop);
  },
  methods: {
    onScroll() {
      if (!this.$refs.location) return;
      if (this.isTouchingList) this.$refs.location.blur();
    },
    onPointerDown(event) {
      if (event.target === this.$refs.location) {
        this.isTouchingList = false;
        return;
      }
      if (event.pointerType === 'touch') {
        this.isTouchingList = true;
      }
    },
    onPointerDownList() {
      if (!this.$refs.location) return;
      this.$refs.location.blur();
    },
    calculatePortsListTop: debounce(() => {
      const fhModalHeader = document.querySelector('.fh-modal__header');
      const searchElement = document.querySelector('.mobile-popup-box__search');

      if (!fhModalHeader || !searchElement) return;

      const fhModalHeaderHeight = fhModalHeader.offsetHeight;
      const searchElementHeight = searchElement.offsetHeight;
      const mobilePortListTop = fhModalHeaderHeight + searchElementHeight;

      setDocumentStyle('--mobile-port-list-top', `${mobilePortListTop}px`);
    }, 200)
  }
};
