<template>
<div>
  <div class="aegean-mobile__date_inputs">
    <label>{{ trans('aegeanlabeldeparturedate') }}:</label>
    <div class="first-input-container">
      <span class="aegean-mobile__date_inputs__input" v-html="dateFirst"  @click="onDateClicked(0)"></span>
      <div class="checkbox-container">
        <div class="checkbox">
          <label :class="{'checked': isSingleTrip }">
            <input name="oneway" type="checkbox" v-model="isSingleTrip"><span class="ml8">{{ trans('cancelreturndate') }}</span>
          </label>
        </div>
      </div>
    </div>    
    <label :class="{ 'invisible' : isSingleTrip }">{{ trans('aegeanlabelarrivaldate') }}:</label>
    <div @click="onDateClicked(1)">
      <span class="aegean-mobile__date_inputs__input" v-html="dateSecond" :class="{ 'invisible' : isSingleTrip }"></span>
    </div>
  </div>  
</div>
</template>

<script>
import { message } from '@/logic/helpers/utils'
import settings from '../../../../../settings';
import { trans } from '@/filters';

export default {
  name: 'SearchFormDateInputs',
  props: ['tripsCount', 'dates', 'mode', 'return'],
  data: () => ({
    format: settings.formats.localizedDateFormat
  }),
  emits: ['onReturnStatusChange', 'dateClicked'],
  computed: {
    // for aegean air, we start with a checkbox on the left hand side of the
    // search form. Consequently, we must use a computed property with a getter
    // and setter
    isSingleTrip: {
      get: function () {
        return !this.$store.state.searchModule.roundTrip;
      },
      set: function (value) {
        let isReturnTrip = !value;
        this.$emit('onReturnStatusChange', isReturnTrip);
      }
    },
    dateFirst: function() {
      if (typeof this.dates[0] !== 'undefined') {
        return this.dates[0].format(this.format);
      } else {
        return this.mode === 'single' ?
          '<span class="placeholder">Select departure date</span>' :
          `<span class="placeholder">${message('multidateholder')} 1</span>`;
      }
    },
    dateSecond: function() {
      if ((this.return === false) && (this.mode === 'single')) {
        return `<span class="placeholder">${message('roundtrip')}?</span>`;
      }
      if (typeof this.dates[1] !== 'undefined') {
        return this.dates[1].format(this.format);
      } else {
        return this.mode === 'single' ?
          '<span class="placeholder">Select return date</span>' :
          `<span class="placeholder">${message('multidateholder')} 2</span>`;
      }
    },
  },
  methods: {
    trans,
    onDateClicked: function(index) {
      this.$emit('dateClicked', index);
    }
  }
}
</script>

<style scoped>
.first-input-container {
  display: flex;
  align-items: top;
  justify-content: space-evenly;
}
.checkbox-container {
  flex: 1;
  text-align: center;
}
</style>