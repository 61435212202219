<template>
  <div ref="mobilePortSelector">
    <div class="mobile-popup-box__search">
      <input
        type="text"
        autofocus="true"
        ref="location"
        class="mobile-popup-box__input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        :spellcheck="false"
        :value="filter"
        :placeholder="placeholder"
        @input="filter = $event.target.value"
        @keyup.enter="onEnterKey()"
      />
    </div>
    <div ref="mobilePortSelectorList" v-if="matchingLocations.length > 0" class="mobile-location-selection-list">
      <AegeanLocationSearchList :locations="matchingLocations" @onListItemClick="onCustomListItemClick" />
    </div>
    <div ref="mobilePortSelectorList" v-else class="mobile-location-selection-list">
      <AegeanLocationSearchList v-if="emptyResultsSuggestion.length > 0" :locations="emptyResultsSuggestion" @onListItemClick="onCustomListItemClick" />
    </div>
  </div>
</template>

<script>
import MobilePortSelector from '@/components/search/mobile/MobilePortSelector';
import AegeanLocationSearchList from '@/affiliates/aegeanair/components/search/common/AegeanLocationSearchList';

export default {
  name: 'MobilePortSelector',
  extends: MobilePortSelector,
  components: {
    AegeanLocationSearchList
  },
  computed: {
    emptyResultsSuggestion() {
      if (this.lastSuggestion) {
        return [this.lastSuggestion];
      } else {
        return this.getSuggestion();
      }
    }
  },
  methods: {
    onEnterKey() {
      if (this.matchingLocations.length > 0) {
        this.onCustomListItemClick(this.matchingLocations[0]);
      }
    },
    onCustomListItemClick(port) {
      this.onSelect(port.LocationAbbr, port.alias);
    }
  }
};
</script>
