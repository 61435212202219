<template>
  <div>
    <div class="search-box search-box--full-screen">
      <div class="search-box__body">
        <div class="port-inputs">
          <div v-for="(destination, index) in destinationsCount" :key="'search-input-fields-' + index">
            <label v-if="index === 0">{{ trans('aegeanlabelfrom') }}:</label>
            <label v-if="index === 1">{{ trans('aegeanlabelto') }}:</label>
            <div class="typeahead-container">
              <input
                type="text"
                readonly="true"
                autocomplete="off"
                :spellcheck="false"
                tabindex="0"
                class="form-control"
                :value="selectedPortNames(destination - 1)"
                :placeholder="portholders(destination)"
                @focus="onPortInputFocus(destination)"
              />
            </div>
          </div>
        </div>

        <!-- dates -->
        <MobileSearchDatesInputs :mode="searchMode" :return="isRoundTrip" :tripsCount="dateInputsCount" :dates="selectedDates" @dateClicked="onDateInputClick" @onReturnStatusChange="onCustomReturnStatusChange">
        </MobileSearchDatesInputs>

        <div class="form-group">
          <div class="row">
            <div class="col-xs-6">
              <div class="icon-input">
                <span class="icon-input__icon"><i class="fh" :class="passengersCount > 1 ? 'fh-users-fill' : 'fh-user-fill'"></i></span>
                <input class="icon-input__input" readonly="true" :value="passengersString" @focus="onPassengersInputFocus()" />
              </div>
            </div>
            <div class="col-xs-6">
              <div class="icon-input">
                <span class="icon-input__icon"><i class="fh fh-vehicles"></i></span>
                <input class="icon-input__input" readonly="true" :value="vehiclesString" @focus="onPassengersInputFocus()" />
              </div>
            </div>
          </div>
        </div>

        <p class="mt0 search-box__error-message" v-if="error.errorMessage !== ''">
          <span v-html="error.errorMessage"></span>
        </p>

        <FhCta :showArrow="true" :isLadda="true" :isWaiting="isWaiting" @click="onSearchClicked">{{ trans('searchbtn') }}</FhCta>
      </div>
    </div>

    <!-- port selection popover -->
    <FhModal v-if="action === actions.port" :title="selectPortTitle" :hasSmallPadding="true" :hasBackButton="true" :hasCloseButton="false" @close="onSimpleModalClose()">
      <template #body>
        <MobilePortSelector
          :locations="locations"
          :target="port"
          :selections="selectedPorts"
          :placeholder="portholders(port)"
          :previousPort="selectedPorts[port - 2]"
          :nextPort="selectedPorts[port]"
          @onPortSelectionChange="onPortSelectionChange"
        >
        </MobilePortSelector>
      </template>
    </FhModal>

    <!-- passengers popover -->
    <MobilePopupBox v-if="action === actions.passengers" @close="onSimpleModalClose()">
      <template #header>
        <MobilePopupBoxHeader :closable="false" :closetext="'Close'" :title="trans('passengersmodaltitle')" @close="onSimpleModalClose()"> </MobilePopupBoxHeader>
      </template>
      <template #body>
        <div>
          <AegeanSearchTravelersPicker @onClose="onSimpleModalClose()" @onTravelersChange="onTravelersChange" />
        </div>
      </template>
    </MobilePopupBox>

    <!-- datepicker popover -->
    <MobilePopupBox v-if="action === actions.dates" @close="onSimpleModalClose()">
      <template #header>
        <MobilePopupBoxHeader :closable="true" :title="trans('traveldatesmodaltitle')" @close="onSimpleModalClose()"> </MobilePopupBoxHeader>
      </template>
      <template #body>
        <MobileTripDatepicker :initialActiveDateIndex="activeDateIndex" @closeMobileDatepicker="onSimpleModalClose()" />
      </template>
    </MobilePopupBox>
  </div>
</template>

<script>
import MobileSearchApp from '@/components/search/mobile/MobileSearchApp';
import MobileSearchDatesInputs from '@/affiliates/aegeanair/components/search/mobile/MobileSearchDatesInputs';
import MobilePortSelector from '@/affiliates/aegeanair/components/search/mobile/MobilePortSelector';
import AegeanSearchTravelersPicker from './AegeanSearchTravelersPicker';
import emitter from '@/emitter';
import { trans } from '@/filters';

export default {
  name: 'AegeanMobileSearchApp',
  extends: MobileSearchApp,
  components: {
    MobileSearchDatesInputs,
    MobilePortSelector,
    AegeanSearchTravelersPicker,
  },
  methods: {
    trans,
    //------------------------------------------------------------------------
    // called when the return status changes (from roundTrip to single and
    // vice versa)
    onCustomReturnStatusChange: function (status) {
      this.$store.dispatch('searchModule/setRoundTrip', status);
      emitter.$emit('onSearchParametersChange');
      this.activeDateIndex = 0;
    },
  },
};
</script>
