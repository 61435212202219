<template>
  <div class="segment-row-mobile">
    <img :src="segment.Details.CompanyIcon" class="desaturate" @error="setDefaultImage" />

    <div class="segment-row-mobile-top">
      <span class="vessel">{{ vesselName }}</span>
    </div>
    <div class="segment-row-mobile-bottom">
      <span class="time">{{ segment.timings.DepTime }} <i class="fh fh-arrow" aria-hidden="true"></i> {{ segment.timings.ArrTime }}</span>

      <span class="ammenities">
        <i class="fh fh-bed has-tooltip" :class="{ unsupported: !hasCabins }"></i>
        <i class="fh fh-vehicles" :class="{ unsupported: !hasGaraze }"></i>
        <i class="fh fh-eTicket" v-if="hasETicket"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { setDefaultImage } from '@/filters';

export default {
  name: 'AeMobileItinerarySegment',
  props: ['segment'],
  methods: {
    setDefaultImage,
  },
  computed: {
    vesselName: function () {
      return this.segment.Details.VesselName;
    },
    hasGaraze: function () {
      return this.segment.Details.hasGaraze;
    },
    hasCabins: function () {
      return this.segment.Details.hasCabins;
    },
    hasETicket: function () {
      return this.segment.Details.eTicket;
    },
  },
};
</script>
