<template>
  <div>
    <div class="search-date-inputs">
      <div class="search-date-inputs__date" @click="onDateClicked(0)" data-test="searchDateInputsMob">
        <span class="typeahead-container__decorator"><CalendarSVG /></span>
        <span v-html="dateFirst"></span>
      </div>
      <div class="search-field-separator"></div>
      <div class="search-date-inputs__date" @click="onDateClicked(1)">
        <span class="typeahead-container__decorator"><CalendarSVG /></span>
        <span v-html="dateSecond"></span>
      </div>
      <div class="search-field-separator" v-if="tripsCount > 2"></div>
      <div class="search-date-inputs__date" @click="onDateClicked(2)" v-if="tripsCount > 2">
        <span class="typeahead-container__decorator"><CalendarSVG /></span>
        <span v-html="dateThird"></span>
      </div>
      <div class="search-field-separator" v-if="tripsCount > 3"></div>
      <div class="search-date-inputs__date" @click="onDateClicked(3)" v-if="tripsCount > 3">
        <span class="typeahead-container__decorator"><CalendarSVG /></span>
        <span v-html="dateFourth"></span>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarSVG from '@/components/shared/CalendarSVG';
import { message } from '@/logic/helpers/utils';
import settings from '../../../settings';

export default {
  name: 'SearchFormDateInputs',
  components: {
    CalendarSVG,
  },
  props: ['tripsCount', 'dates', 'mode', 'return'],
  data: () => ({
    format: settings.formats.localizedDateFormatLong,
  }),
  emits: ['dateClicked'],
  computed: {
    dateFirst: function () {
      if (typeof this.dates[0] !== 'undefined') {
        return this.dates[0].format(this.format);
      } else {
        return this.mode === 'single' ? '<span class="mock-placeholder">Select departure date</span>' : `<span class="mock-placeholder">${message('multidateholder')} 1</span>`;
      }
    },
    dateSecond: function () {
      if (this.return === false && this.mode === 'single') {
        return `<span class="mock-placeholder">${message('roundtrip')}?</span>`;
      }
      if (typeof this.dates[1] !== 'undefined') {
        return this.dates[1].format(this.format);
      } else {
        return this.mode === 'single' ? '<span class="mock-placeholder">Select return date</span>' : `<span class="mock-placeholder">${message('multidateholder')} 2</span>`;
      }
    },
    dateThird: function () {
      if (typeof this.dates[2] !== 'undefined') {
        return this.dates[2].format(this.format);
      } else {
        return `<span class="mock-placeholder">${message('multidateholder')} 3</span>`;
      }
    },
    dateFourth: function () {
      if (typeof this.dates[3] !== 'undefined') {
        return this.dates[3].format(this.format);
      } else {
        return `<span class="mock-placeholder">${message('multidateholder')} 4</span>`;
      }
    },
  },
  methods: {
    onDateClicked: function (index) {
      this.$emit('dateClicked', index);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/_variables.scss';
@import '@/assets/sass/_colors.scss';
@import '@/assets/sass/ui-kit/_brand-typography-mixins.scss';

// TODO: This component has the same name and classes as the desktop version one
// and will potentially create conflicts

// table for horizontal dates in the new mobile search form
.search-date-inputs {
  margin: 24px 0;
}

// The element showing the date
.search-date-inputs__date {
  @include brandText('7');
  position: relative;
  text-align: left;
  // background-image: url('https://images.ferryhopper.com/assets/icons/ferryicons-calendar.svg');
  // background-size: 15px;
  // background-position: left 5px bottom 50%;
  // background-repeat: no-repeat;
  padding: 7px 7px 7px 28px;
  cursor: pointer;
}

// on mobile devices, spans are used instead of inputs, in order to avoid input
// focusing when the landing page loads (this is a problem mostly on ios devices)
// hence, we mimic placeholders using a span
//to be investigated it used to be
//.search-date-inputs__date ::v-deep .mock-placeholder {
.search-date-inputs__date .mock-placeholder {
  color: $gray;
  font-weight: 400;
}

// Override the top positioning of decorator, because the
// calendar icon is slightly shorter than other, and we want to
// align it vertically with text
// HACK - MAGIC NUMBERS SPECIFIC TO THE SVG
.typeahead-container__decorator {
  top: 8px;
  width: 18px;
  height: 18px;
}
</style>
