<template>
  <div class="aegean-itinerary-header clearfix">
    <span class="description pull-left">
      <JourneyTitle :ports="stops" v-if="indirect || isSuperPortSearch" />
    </span>

    <span v-if="indirect" class="pull-right indirect-trip-time">
      <i class="fh fh-clock"></i> {{ durationString(duration) }}<span v-if="overnight">: {{ trans('arrival') }} {{ shortDate(arrival) }}</span>
    </span>
  </div>
</template>

<script>
import JourneyTitle from '@/components/shared/JourneyTitle';
import { trans, shortDate, durationString } from '@/filters';

export default {
  name: 'AeMobileItineraryHeader',
  components: { JourneyTitle },
  props: ['departure', 'arrival', 'overnight', 'stops', 'indirect', 'duration', 'isSuperPortSearch'],
  methods: {
    trans,
    shortDate,
    durationString,
  },
};
</script>
