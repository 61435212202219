<template>
  <div class="search-travelers-picker">
    <TravelersControl
      :class="'mt60'"
      :icon="'users-fill'"
      :label="trans('passengers')"
      :travelerType="'passenger'"
      :travelersCount="internalPassengers"
      :minTravelersReached="internalPassengers === 1"
      :maxTravelersReached="internalPassengers === 9"
      @addTraveler="addTraveler"
      @removeTraveler="removeTraveler"
    />
    <TravelersControl
      v-if="isPetsBookingActivated"
      :class="'mt60'"
      :icon="'pet'"
      :label="trans('pets')"
      :travelerType="'pet'"
      :travelersCount="internalPets"
      :minTravelersReached="internalPets === 0"
      :maxTravelersReached="internalPets === 9"
      @addTraveler="addTraveler"
      @removeTraveler="removeTraveler"
    />
    <TravelersControl
      :class="'mt60'"
      :icon="'vehicles'"
      :label="trans('vehicles')"
      :travelerType="'vehicle'"
      :travelersCount="internalVehicles"
      :minTravelersReached="internalVehicles === 0"
      :maxTravelersReached="internalVehicles === 4"
      @addTraveler="addTraveler"
      @removeTraveler="removeTraveler"
    />
    <div class="search-travelers-picker__footer">
      <FhCta @click="onDone()">{{ trans('donemodal') }}</FhCta>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';
import settings from '@/settings';
import TravelersControl from '@/components/shared/Travelers/TravelersControl';
// Vuex
import { mapState } from 'vuex';
import { eventTravelersClicked } from '@/logic/services/events/createSharedEvents';

export default {
  name: 'SearchTravelersPicker',
  components: {
    TravelersControl
  },
  emits: ['onTravelersChange'],
  data: () => ({
    // We use internal values in this component because we do not want to sync with global state until
    // the "Done" button is clicked
    internalPassengers: 1,
    internalPets: 0,
    internalVehicles: 0
  }),
  created() {
    this.internalPassengers = this.passengers;
    this.internalPets = this.pets;
    this.internalVehicles = this.vehicles;
  },
  computed: {
    ...mapState({
      passengers: state => state.searchModule.passengers,
      pets: state => state.searchModule.pets,
      vehicles: state => state.searchModule.vehicles,
      isPetsBookingActivated: state => state.searchModule.isPetsBookingActivated
    })
  },
  methods: {
    trans,
    isPassenger(type) {
      return type === 'passenger';
    },
    isPet(type) {
      return type === 'pet';
    },
    isVehicle(type) {
      return type === 'vehicle';
    },
    addTraveler(traveler) {
      if (this.isPassenger(traveler) && this.internalPassengers < settings.limits.MAXIMUM_PASSENGERS) {
        this.internalPassengers++;
        eventTravelersClicked('PASSENGER', 'ADDED');
      }
      if (this.isPet(traveler) && this.internalPets < settings.limits.MAXIMUM_PETS) {
        this.internalPets++;
        eventTravelersClicked('PET', 'ADDED');
      }
      if (this.isVehicle(traveler) && this.internalVehicles < settings.limits.MAXIMUM_VEHICLES) {
        this.internalVehicles++;
        eventTravelersClicked('VEHICLE', 'ADDED');
      }
    },
    removeTraveler(traveler) {
      if (this.isPassenger(traveler) && this.internalPassengers > settings.limits.MINIMUM_PASSENGERS) {
        this.internalPassengers--;
        eventTravelersClicked('PASSENGER', 'REMOVED');
      }
      if (this.isPet(traveler) && this.internalPets > settings.limits.MINIMUM_PETS) {
        this.internalPets--;
        eventTravelersClicked('PET', 'REMOVED');
      }
      if (this.isVehicle(traveler) && this.internalVehicles > settings.limits.MINIMUM_VEHICLES) {
        this.internalVehicles--;
        eventTravelersClicked('VEHICLE', 'REMOVED');
      }
    },
    onDone() {
      this.$emit('onTravelersChange', this.internalPassengers, this.internalVehicles, this.internalPets);
    }
  }
};
</script>
