<template>
  <div class="container booking-flow__tab">
    <!-- passenger section header -->
    <AegeanBookingFlowSectionHeader :order="'2'" :title="trans('tabpassengerstitle')" id="passengers-tab-top" />

    <!-- Top filters and buttons -->
    <div class="row">
      <div class="col-xs-12 fp-main-column">
        <AegeanSeatingAppTopBar :isOccupied="isOccupied" :hasCarsRestriction="hasCarsRestriction" />

        <!-- seat selection and price for each trip -->
        <template v-for="(trip, tripIndex) in trips" :key="'seat-selection-box-' + trip.id">
          <div class="mt-60">
            <!-- trip header -->
            <div class="aegean-section-bar-results">
              <div class="bar">
                <span>{{ shortDate(trip.timings.DepartureDateTime) }} - {{ trip.timings.DepTime }}</span>
              </div>
            </div>
            <!-- trip passengers forms -->
            <div>
              <transition-group name="fade" tag="div" appear>
                <PassengerSeatingComponent
                  class="px15"
                  v-for="passenger in trip.passengers"
                  :key="tripIndex + 'passenger-seating-' + passenger.passengerIndex"
                  :trip="trip"
                  :tripIndex="tripIndex"
                  :passenger="passenger"
                  :passengerIndex="passenger.passengerIndex"
                  :isOccupied="isOccupied"
                  @onLoyaltyChange="onLoyaltyChange"
                  @onResidenceDiscountChange="onResidenceDiscountChange"
                />

                <VehicleSeatingComponent
                  class="px15"
                  v-for="vehicle in trip.vehicles"
                  :key="tripIndex + 'vehicle-seating-' + vehicle.vehicleIndex"
                  :trip="trip"
                  :tripIndex="tripIndex"
                  :vehicle="vehicle"
                  :isOccupied="isOccupied"
                  :vehicleOptions="vehicleAccommodations"
                  :passengers="passengers"
                />
              </transition-group>
            </div>
          </div>
          <!-- trip price table -->
          <div class="cart-container" :class="{ mb0: tripIndex === trips.length - 1 }">
            <AegeanCartItem :trip="trip" :key="'cart-item-' + tripIndex" :showOverallPrice="true" :priceVerified="hasVerifiedAvailability" :seats="trip.SeatingAnalysis"></AegeanCartItem>
            <AegeanPricingError :trip="trip" :key="'cart-item-trip-selections-warning-' + tripIndex" />
          </div>
        </template>

        <!-- final price for all trips -->
        <div class="cart-container">
          <div class="cart-item-box">
            <div class="cart-item-heading final clearfix">
              <span class="cart-price-text cart-price-label pull-left">{{ trans('aegeanlabeltotalprice') }}</span>
              <span class="cart-price-text cart-price-value pull-right" v-html="aegeanCurrency(overall)"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <!-- seat checkout buttons -->
        <div v-if="hasVerifiedAvailability">
          <CheckoutSingleButtonComponent :checkoutButtonText="trans('proceedtobooking')" @onCheckoutClick="onFinalCheckout" />
        </div>

        <div class="text-center" v-else>
          <CheckoutButtonsComponent
            :backButtonText="trans('back')"
            :nextButtonText="trans('checkprices')"
            :isLadda="true"
            :isWaiting="isWaitingForPrices"
            :disable="isPricingDisabled"
            @onBackClicked="onBackClicked"
            @onNextClicked="onPricingRequested"
          />
          <small class="btn-block text-danger mb20 mt20" v-if="hasNetworkError"> <i class="fh fh-unhappy"></i> {{ trans('connectionerror') }} </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeatingApp from '@/components/seats/SeatingApp';
import AegeanBookingFlowSectionHeader from '@/affiliates/aegeanair/components/shared/BookingFlowSectionHeader';
import AegeanCartItem from '@/affiliates/aegeanair/components/results/cart/AegeanCartItem';
import AegeanSeatingAppTopBar from '@/affiliates/aegeanair/components/seats/AegeanSeatingAppTopBar';
import AegeanPricingError from './AegeanPricingError';
import CheckoutSingleButtonComponent from '@/affiliates/aegeanair/components/shared/CheckoutButtons/CheckoutSingleButtonComponent';
import CheckoutButtonsComponent from '@/affiliates/aegeanair/components/shared/CheckoutButtons/CheckoutButtonsComponent';
import { trans, shortDate, aegeanCurrency } from '@/filters';

export default {
  extends: SeatingApp,
  components: {
    AegeanBookingFlowSectionHeader,
    AegeanCartItem,
    AegeanSeatingAppTopBar,
    AegeanPricingError,
    CheckoutSingleButtonComponent,
    CheckoutButtonsComponent,
  },
  methods: {
    trans,
    aegeanCurrency,
    shortDate,
  },
};
</script>
