<template>
  <div>
    <p class="text-6 text-center mt40 mb10">
      <span>{{ internalPassengersString }}</span>
    </p>
    <div class="text-center">
      <i class="fh fh-minus icon-btn" :class="{ 'icon-btn--disabled': internalPassengers <= 1 }" @click="removePassenger()"> </i>
      <i class="fh fh-plus icon-btn" :class="{ 'icon-btn--disabled': internalPassengers >= 9 }" @click="addPassenger()"> </i>
    </div>
    <hr />
    <p class="text-center text-6">
      <span>{{ internalVehiclesString }}</span>
    </p>
    <div class="text-center">
      <i class="fh fh-minus icon-btn" :class="{ 'icon-btn--disabled': internalVehicles < 1 }" @click="removeVehicle()"> </i>
      <i class="fh fh-plus icon-btn" :class="{ 'icon-btn--disabled': internalVehicles >= 4 }" @click="addVehicle()"> </i>
    </div>

    <div class="checkout-buttons checkout-buttons--separation mt40">
      <button class="btn btn-ghost btn-ghost--primary checkout-buttons__single-button" @click="onCancel()">{{ trans('cancelmodal') }}</button>
      <button class="btn btn-primary checkout-buttons__single-button" @click="onDone()">{{ trans('donemodal') }}</button>
    </div>
  </div>
</template>

<script>
import settings from '@/settings';
import { createPassengersString, createVehiclesString } from '../../../../../logic/generators/phrases';
import { trans } from '@/filters';

export default {
  name: 'SearchTravelersPicker',
  emits: ['onClose', 'onTravelersChange'],
  data: () => ({
    // We use internal values in this component because we do not want to sync with global state until
    // the "Done" button is clicked
    internalPassengers: 1,
    internalVehicles: 0
  }),
  created() {
    this.internalPassengers = this.$store.state.searchModule.passengers;
    this.internalVehicles = this.$store.state.searchModule.vehicles;
  },
  methods: {
    trans,
    addPassenger() {
      if (this.internalPassengers < settings.limits.MAXIMUM_PASSENGERS) {
        this.internalPassengers++;
      }
    },
    addVehicle() {
      if (this.internalVehicles < settings.limits.MAXIMUM_VEHICLES) {
        this.internalVehicles++;
      }
    },
    removePassenger() {
      if (this.internalPassengers > settings.limits.MINIMUM_PASSENGERS) {
        this.internalPassengers--;
      }
    },
    removeVehicle() {
      if (this.internalVehicles > settings.limits.MINIMUM_VEHICLES) {
        this.internalVehicles--;
      }
    },
    onCancel() {
      this.$emit('onClose');
    },
    onDone() {
      this.$emit('onTravelersChange', this.internalPassengers, this.internalVehicles);
    }
  },
  computed: {
    internalPassengersString() {
      // Note that we do not use state values here, because these are internally modified in this component
      return createPassengersString(this.internalPassengers);
    },
    internalVehiclesString() {
      // Note that we do not use state values here, because these are internally modified in this component
      return createVehiclesString(this.internalVehicles);
    }
  }
};
</script>
