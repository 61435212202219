<template>
<div>
  <AegeanNavBar />
  <div class="page-content" :class="{'page-content--no-background' : isFirstLoad === false }">
    <AegeanNavigationTabs />
    <template v-if="ready">
      <!--<transition-group name="main-content-tab" mode="out-in" tag="div">-->
        <AegeanMobileSearchApp v-show="(activeTab === 'search') || showAllApps" key="tab-1"></AegeanMobileSearchApp>
        <hr style="height: 50px; background:gray;" v-if="showAllApps" />
        <AegeanResultsApp  v-show="(activeTab === 'results') || showAllApps" key="tab-2"></AegeanResultsApp>
        <hr style="height: 50px; background:gray;" v-if="showAllApps" />
        <AegeanMobileSeatingApp  v-show="(activeTab === 'passengers') || showAllApps" key="tab-3"></AegeanMobileSeatingApp>
        <hr style="height: 50px; background:gray;" v-if="showAllApps" />
        <AegeanBookingApp v-show="(activeTab === 'book') || showAllApps" key="tab-4"></AegeanBookingApp>
        <!--</transition-group>-->
      </template>
      <InfoModal />
      <RequestRefreshPopup v-show="shouldAskForPageRefresh" :variant="'aegeanair'"/>
  </div>
</div>
</template>

<script>
import { getConfigurationArray } from '@/logic/helpers/utils'
import { each as _each } from 'lodash-es'
import emitter from '@/emitter'
import { initializeDefaultCRS } from '@/logic/services/initializeDefaultCRS'
import AegeanNavigationTabs from '@/affiliates/aegeanair/components/AegeanNavigationTabs'
import { logException } from '@/logic/services/events/errorLogging'
import PortsRepository from '@/logic/repositories/PortsRepository'

import AegeanMobileSearchApp from '@/affiliates/aegeanair/components/search/mobile/MobileSearchApp'
import AegeanNavBar from '@/affiliates/aegeanair/AegeanNavBar'
import AegeanBookingApp from '@/affiliates/aegeanair/components/book/BookingApp'
import AegeanMobileSeatingApp from '@/affiliates/aegeanair/components/seats/MobileSeatingApp'
import AegeanResultsApp from '@/affiliates/aegeanair/components/results/ResultsApp'
import RequestRefreshPopup from '@/components/shared/modals/RequestRefreshPopup'

// override of global components
import { handlePromise } from '../../logic/helpers/handlePromise'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AegeanFerryMobileApp',
  components: {
    AegeanNavBar,
    AegeanMobileSearchApp,
    AegeanNavigationTabs,
    AegeanResultsApp,
    AegeanMobileSeatingApp,
    AegeanBookingApp,
    RequestRefreshPopup
  },
  data: function() {
    return {
      showAllApps: false
    };
  },
  computed: {
    ...mapGetters({
      isAutoSearch: 'searchModule/isAutoSearch'
    }),
    ready: function() {
      return this.$store.state.navigationModule.appReady;
    },
    activeTab: function() {
      return this.$store.state.navigationModule.activeTab;
    },
    isFirstLoad: function() {
      return this.$store.state.navigationModule.initialLoad;
    },
    shouldAskForPageRefresh(){
      return this.$store.state.navigationModule.shouldAskForPageRefresh;
    }
  },

   watch: {
    shouldAskForPageRefresh: function(shouldAsk) {
      shouldAsk
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }
  },
  
  created: async function() {
    
    if (this.isFirstLoad) {
      this.resetState();
    }

    const  { response, error } = await handlePromise(initializeDefaultCRS());    
    if (error) {
      logException('PrimaryDataLoading', { error })
      // TODO: We could as well show a nice error inside the loading overlay at this stage 
      // and allow user to refresh
      return;
    }

    try {

      if (!this.isAutoSearch) {
        this.hideOverlay();
      }
    } catch (exception) {
      this.hideOverlay();
    }
    
    this.$store.commit('navigationModule/setAppReady');
    emitter.$on('onFirstResultCollected', () => this.hideOverlay());
    emitter.$on('onSearchError', () => this.hideOverlay());
  },
  methods: {  
    ...mapMutations({
      resetState: 'RESET_STATE',
    }),
    hideOverlay: function () {
      try {
        let loadingOverlay = document.getElementById('loadingOverlay');
        if (loadingOverlay) {
          loadingOverlay.parentNode.removeChild(loadingOverlay);
        }
      } catch (exception) {

      }
    }
  }
};
</script>
