<template>
  <div class="mobile-popup-box__header">
    <span @click="onClose()" v-if="closable" data-test="mobilePopupCloseBtn">
      <i class="fh fh-arrow mobile-popup-box__header__back-arrow"></i>
    </span>
    <span class="mobile-popup-box__header__title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'MobilePopupBoxHeader',
  props: {
    title: {
      default: '',
      type: String
    },
    closable: {
      default: false,
      type: Boolean
    },
    closetext: {
      default: '',
      type: String
    }
  },
  emits: ['close'],
  methods: {
    onClose: function() {
      this.$emit('close');
    }
  }
};
</script>
