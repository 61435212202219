<template>
  <div class="aegean-selectable-itinerary" :class="{ selected: selected, available: trip.available, alternative: hasHeader }" @click="onClick">
    <AeMobileItineraryHeader
      v-if="trip.isDirectTrip === false || isSuperPortSearch === true"
      :departure="segments[0].timings.DepartureDateTime"
      :arrival="arrival"
      :indirect="trip.isDirectTrip === false"
      :isSuperPortSearch="isSuperPortSearch"
      :duration="trip.duration"
      :overnight="isOvernight"
      :stops="trip.stops"
    />

    <div class="content clearfix">
      <div class="ae-itinerary-details-section pull-left">
        <AeMobileItinerarySegment v-for="(segment, segmentIndex) in segments" :key="trip.id + segmentIndex" :segment="segment" />
      </div>

      <div class="mobile-itinerary-price pull-right">
        <template v-if="trip.available">
          <span class="price-tag" v-html="aegeanCurrency(trip.minPrice)"></span>
          <span class="call-to-action">{{ trans('actionselect') }}</span>
        </template>
        <template v-else>
          <span class="price-tag">
            <i class="fh fh-negative text-accent"></i>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AeMobileItinerarySegment from '@/affiliates/aegeanair/components/results/itineraries/AeMobileItinerarySegment';
import AeMobileItineraryHeader from '@/affiliates/aegeanair/components/results/itineraries/AeMobileItineraryHeader';
import { trans, aegeanCurrency } from '@/filters';

export default {
  name: 'AeMobileItinerary',
  components: {
    AeMobileItineraryHeader,
    AeMobileItinerarySegment,
  },
  props: {
    trip: Object,
    available: Boolean,
    selected: Boolean,
    isSuperPortSearch: Boolean,
  },
  emits: ['click'],
  data: function () {
    return {
      showModal: false,
    };
  },
  methods: {
    trans,
    aegeanCurrency,
    onClick() {
      this.$emit('click', this.trip.id, this.trip.available);
    },
  },
  computed: {
    isDirect: function () {
      return this.trip.isDirectTrip;
    },
    segments: function () {
      if (this.isDirect === true) {
        return [this.trip];
      } else {
        return this.trip.segments;
      }
    },
    primarySegment: function () {
      if (this.isDirect === true) {
        return this.trip;
      } else {
        return this.trip.segments[0];
      }
    },
    arrival: function () {
      if (this.isDirect === true) {
        return this.trip.timings.ArrivalDateTime;
      } else {
        return this.trip.segments[this.trip.segments.length - 1].timings.ArrivalDateTime;
      }
    },
    isOvernight: function () {
      return this.trip.overnight;
    },
    hasHeader: function () {
      return this.isSuperPortSearch;
    },
  },
};
</script>
