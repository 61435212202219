<template>
  <div ref="mobilePortSelector">
    <div class="mobile-popup-box__search pb16 pt2">
      <input
        type="text"
        autofocus="true"
        ref="location"
        class="mobile-popup-box__input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        :spellcheck="false"
        :value="filter"
        :placeholder="placeholder"
        @input="onInput($event)"
        @keyup.enter="onEnterKey()"
      />
      <i v-if="filter !== ''" class="fh fh-close mobile-popup-box__clear" @click="clearFilter"></i>
    </div>
    <div ref="mobilePortSelectorList" class="mobile-location-selection-list" v-if="matchingLocations.length > 0">
      <ReturnLocationsSearchList v-if="hasReturnLocations" :locations="matchingReturnLocations" :selectedPort="selectedPort" @onListItemClick="onSelect" />
      <PopularLocationsSearchList
        :locations="matchingLocations"
        :isPortsFiltered="filter !== ''"
        :isDirectPortsShown="isDirectPortsShown"
        :hasReturnLocations="hasReturnLocations"
        :selectedPort="selectedPort"
        :siblingPort="siblingPort"
        @onListItemClick="onSelect"
      />
      <AllLocationsSearchList :allLocations="matchingAllLocations" v-if="filter === ''" @onListItemClick="onSelect" />
    </div>
    <div ref="mobilePortSelectorList" class="mobile-location-selection-list" v-else>
      <NotFoundLocationSearchList v-if="lastSuggestion.destinations !== null" :lastSuggestion="lastSuggestion" :lastUserInput="filter" @onListItemClick="onSelect" />
      <AllLocationsSearchList :destinations="lastSuggestion.destinations" :allLocations="matchingAllLocations" @onListItemClick="onSelect" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import { clean } from '@/logic/helpers/utils';
import SuggestionsEngine from '@/logic/managers/SuggestionsEngine';
import { logException } from '@/logic/services/events/errorLogging';
import SearchPortMixin from './../mixins/SearchPortMixin';
import PopularLocationsSearchList from '@/components/search/common/PopularLocationsSearchList';
import AllLocationsSearchList from '@/components/search/common/AllLocationsSearchList';
import ReturnLocationsSearchList from '@/components/search/common/ReturnLocationsSearchList';
import NotFoundLocationSearchList from '@/components/search/common/NotFoundLocationSearchList';
import MobilePortSelectorMixin from '@/components/search/mixins/MobilePortSelectorMixin';
import { eventPortSelected } from '@/logic/services/events/createSearchEvents';

export default {
  name: 'MobilePortSelector',
  components: {
    PopularLocationsSearchList,
    AllLocationsSearchList,
    ReturnLocationsSearchList,
    NotFoundLocationSearchList
  },
  props: ['locations', 'locationsByAlias', 'target', 'selections', 'previousPort', 'nextPort', 'placeholder', 'searchMode'],
  mixins: [SearchPortMixin, MobilePortSelectorMixin],
  data() {
    return {
      filter: '',
      engine: null
    };
  },
  emits: ['onPortSelectionChange'],
  created() {
    this.engine = this.globalCobrandedVariant === 'aegeanair' ? new SuggestionsEngine([], 5) : new SuggestionsEngine([]);
    // initialize engine locations
    this.engine.setLocations(clean(this.locations));
    // if the typeahead is created for an already selected (user-defined) port,
    // set the filter to an initial value that corresponds to this port alias
    try {
      if (this.selections && this.selections[this.target].alias) {
        var newFilter = this.selections[this.target].alias;
        this.filter = newFilter;
      }
    } catch (exception) {}
  },
  mounted() {
    // once the component is visible, focus on the input field and select it's
    // contents, so that the user can immediately start typing to correct his
    // input
    try {
      this.$refs.location.focus();
      this.$refs.location.select();
    } catch (exception) {
      logException('MobilePortSelect:Autofocus', exception);
    }
  },
  computed: {
    siblingPort() {
      return this.previousPort || this.nextPort;
    }
  },
  methods: {
    onInput(event) {
      this.filter = event.target.value;
      this.$refs.mobilePortSelectorList.scrollTop = 0;
    },
    onEnterKey() {
      if (this.matchingLocations.length > 0) {
        this.onSelect(this.matchingLocations[0].LocationAbbr, this.matchingLocations[0].alias);
      }
    },
    onSelect(abbreviation, shortname, portLocationCategory, locationIndex) {
      this.$emit('onPortSelectionChange', abbreviation);
      this.filter = shortname;
      this.matchingLocationsReSync(this.filter);
      eventPortSelected({
        port_code: abbreviation,
        departure_or_destination: this.target === 0 ? 'departure' : 'destination',
        type_of_search: this.searchMode,
        user_action: portLocationCategory,
        port_position: locationIndex + 1
      });
    },
    // The onPortSelectionChange emit is destroying the MobilePortSelector component and the this.filter is no longer triggering the computed matchingLocations.
    // So in that case we pass the filter as function attribute
    matchingLocationsReSync(filter) {
      if (filter === '') return this.getSuggestion();
      return this.engine.sync(filter, this.siblingPort);
    },
    clearFilter() {
      this.filter = '';
      // Upon clicking the X button the lastSuggestion must be set with the previous or next port
      this.setLastSuggestion();
      this.$refs.mobilePortSelectorList.scrollTop = 0;
    }
  }
};
</script>
