<template>
  <div>
    <div class="search-box search-box--full-screen">
      <div class="search-box__body">
        <div class="mt30">
          <SearchModeSwitcher :mode="searchMode" @switchMode="switchMode" />
        </div>

        <div class="port-inputs">
          <div class="typeahead-container typeahead-container--with-icon" data-test="portInput" v-for="(destination, destinationIndex) in destinationsCount" :key="'typeahead-container-' + destination">
            <div class="search-field-separator" v-if="destinationIndex > 0"></div>
            <div class="typeahead-container__decorator">
              <PinSVG />
            </div>
            <input
              type="text"
              readonly="true"
              autocomplete="off"
              :spellcheck="false"
              tabindex="0"
              class="form-control"
              :value="selectedPortNames(destination - 1)"
              :placeholder="portholders(destination)"
              @focus="onPortInputFocus(destination)"
              data-hj-allow
              data-test="portInputText"
            />

            <SearchSwapIconButton v-if="destination === 1 && isSingleTrip" isBig :hasHover="false" :flip="flipDirection" :isDisabled="!canSwitchDirections" @click="switchPortsDirection()" />

            <span v-if="searchMode === 'multi' && destination < destinationsCount" class="multi-trip-connection-circle">{{ destination }}</span>
          </div>
        </div>

        <div v-if="searchMode == 'multi'" class="stack-h-right">
          <span class="btn-label" @click="addDestination()" :class="{ 'btn-label--disabled': canAddDestination }">
            {{ trans('addtriplabel') }}
          </span>
          <span class="btn-label" @click="removeDestination()" :class="{ 'btn-label--disabled': !canRemoveDestination }">
            {{ trans('removetriplabel') }}
          </span>
        </div>

        <SearchDatesInputs :mode="searchMode" :return="isRoundTrip" :tripsCount="dateInputsCount" :dates="selectedDates" @dateClicked="onDateInputClick"> </SearchDatesInputs>

        <div class="mb20">
          <div class="row">
            <div class="col-xs-12">
              <TravelersCounter @onTravelerClick="onPassengersInputFocus" />
            </div>
          </div>
        </div>
        <SearchButtonBar :isWaiting="isWaiting" :error="error" @onSearchClicked="onSearchClicked()" />

        <ToggleSwitch
          v-if="showAccommodationSwitchForMobile && !isCobrandedPage"
          class="mt24"
          :value="isSearchingForAccommodation"
          :hasFixedHeight="false"
          :text="trans('searchmask.switch.searchAccommodation')"
          @input="setSearchingForAccommodation"
        />
      </div>
    </div>

    <!-- port selection popover -->
    <FhModal v-if="action === actions.port" :title="selectPortTitle" :hasSmallPadding="true" :hasBackButton="true" :hasCloseButton="false" @close="onSimpleModalClose()">
      <template #body>
        <MobilePortSelector
          :locations="locations"
          :locationsByAlias="locationsByAlias"
          :target="port - 1"
          :selections="selectedPorts"
          :placeholder="portholders(port)"
          :previousPort="selectedPorts[port - 2]"
          :nextPort="selectedPorts[port]"
          :searchMode="searchMode"
          @onPortSelectionChange="onPortSelectionChange"
          @onComponentDestroy="onComponentDestroy"
        >
        </MobilePortSelector>
      </template>
    </FhModal>

    <!-- datepicker popover -->
    <MobilePopupBox v-if="action === actions.dates" @close="onSimpleModalClose()">
      <template #header>
        <MobilePopupBoxHeader :closable="true" :title="trans('traveldatesmodaltitle')" @close="onSimpleModalClose()"> </MobilePopupBoxHeader>
      </template>
      <template #body>
        <MobileTripDatepicker :initialActiveDateIndex="activeDateIndex" @closeMobileDatepicker="onSimpleModalClose()" />
      </template>
    </MobilePopupBox>

    <!-- Passengers, pets and vehicles modal -->
    <FhModal v-if="showTravelersModal" @close="onSimpleModalClose" :title="trans(`${passengerHeader}`)">
      <template #body>
        <SearchTravelersPicker @onTravelersChange="onTravelersChange" />
      </template>
    </FhModal>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { sortBy as _sortBy, cloneDeep as _cloneDeep } from 'lodash-es';

import { message, getPageLanguage } from '@/logic/helpers/utils';
import emitter from '@/emitter';
import SearchModeSwitcher from '@/components/search/common/SearchModeSwitcher';
import SearchDatesInputs from '@/components/search/mobile/SearchDatesInputs';
import MobilePopupBox from '@/components/search/mobile/MobilePopupBox';
import MobilePopupBoxHeader from '@/components/search/mobile/MobilePopupBoxHeader';
import MobilePortSelector from '@/components/search/mobile/MobilePortSelector';
import SearchTravelersPicker from '@/components/search/mobile/SearchTravelersPicker';
import PortsRepository from '@/logic/repositories/PortsRepository';
import SearchSwapIconButton from '@/components/search/SearchSwapIconButton';
import PinSVG from '@/components/shared/PinSVG';
import TravelersCounter from '@/components/shared/Travelers/TravelersCounter';
import DatePickerSpinner from '@/components/search/common/DatePickerSpinner';
import { mapActions, mapGetters } from 'vuex';
import SearchAppMixin from '@/components/search/mixins/SearchAppMixin';
import AccommodationMixin from '@/components/search/mixins/AccommodationMixin';
import SearchButtonBar from '@/components/search/desktop/SearchButtonBar';
import { ga4Event } from '@/logic/services/events/EventService';
import { eventStepReached } from '@/logic/services/events/createSharedEvents';
import { trans } from '@/filters';
import { defineAsyncComponent } from 'vue';

const MobileTripDatepicker = defineAsyncComponent({
  loader: () => import('@/components/shared/Datepickers/MobileTripDatepicker'),
  loadingComponent: DatePickerSpinner,
});
//----------------------------------------------------------------------------
// possible actions in the mobile search form, used for opening/closing the
// appropriate mobile modals
let actions = {
  nothing: 0,
  port: 1,
  dates: 2,
  passengers: 3,
  departure: 4,
};

// stop body scrolling on ios
var freezeVp = function (e) {
  try {
    e.stopImmediatePropagation();
  } catch (exception) {
    // errors might be thrown by some browsers (e.g Chrome) that do not allow
    // preventDefault on passive event listeners
    // (https://developers.google.com/web/updates/2017/01/scrolling-intervention)
  }
};

function stopBodyScrolling(bool) {
  try {
    if (bool === true) {
      document.getElementsByTagName('body')[0].classList.add('noscroll');
      document.getElementsByTagName('body')[0].addEventListener('touchmove', freezeVp, false);
    } else {
      document.getElementsByTagName('body')[0].classList.remove('noscroll');
      document.getElementsByTagName('body')[0].removeEventListener('touchmove', freezeVp, false);
    }
  } catch (ex) {}
}

export default {
  name: 'MobileSearchApp',
  components: {
    PinSVG,
    SearchModeSwitcher,
    SearchDatesInputs,
    MobilePopupBox,
    MobilePopupBoxHeader,
    MobilePortSelector,
    SearchTravelersPicker,
    MobileTripDatepicker,
    SearchSwapIconButton,
    TravelersCounter,
    SearchButtonBar,
    DatePickerSpinner,
  },
  mixins: [SearchAppMixin, AccommodationMixin],
  data: function () {
    return {
      actions: actions,
      show: false,
      action: 0,
      port: 0,
      // all available locations sorted by popularity
      locations: [],
      // all available locations sorted by alias
      locationsByAlias: [],
      activeDateIndex: 0,
      errorMessage: '',
      isWaiting: false,
      flipDirection: false,
      showTravelersModal: false,
    };
  },
  created() {
    const allPorts = PortsRepository.getAllPorts();
    this.locations = allPorts;
    // case-insensitive sorting based on language without punctuation
    this.locationsByAlias = allPorts.sort((a, b) => a.alias.toLowerCase().localeCompare(b.alias.toLowerCase(), getPageLanguage(), { ignorePunctuation: true }));

    if (this.isAutoSearch || !this.isActiveTabSearch) return;
    eventStepReached();
  },
  mounted() {
    emitter.$on('onFirstResultCollected', () => (this.isWaiting = false));

    if (this.isAutoSearch) {
      this.onSearchClicked();
      this.$store.commit('searchModule/setAutoSearch', false);
    }
  },
  computed: {
    ...mapGetters({
      selectedDates: 'searchModule/getSelectedDates',
      isRoundTrip: 'searchModule/isRoundTrip',
      destinationsCount: 'searchModule/destinationsCount',
      canAddDestination: 'searchModule/canAddDestination',
      canRemoveDestination: 'searchModule/canRemoveDestination',
      passengersString: 'searchModule/passengersString',
      vehiclesString: 'searchModule/vehiclesString',
      selectedPorts: 'searchModule/selectedPorts',
    }),
    //------------------------------------------------------------------------
    dateInputsCount() {
      return this.searchMode === 'multi' ? this.tripsCount : 2;
    },
    //------------------------------------------------------------------------
    selectPortTitle: function () {
      if (this.searchMode === 'single') {
        return this.port === 1 ? message('departureporttitle') : message('destinationporttitle');
      } else {
        return message('multiselectlocation' + this.port);
      }
    },
    canSwitchDirections: function () {
      return this.searchMode === 'single' && (this.selectedPorts[0].LocationAbbr || this.selectedPorts[1].LocationAbbr);
    },
    isSingleTrip() {
      return this.searchMode === 'single';
    },
    passengerHeader() {
      return this.isPetsBookingActivated ? 'searchmaskpassengerpetstitle' : 'searchmaskpassengertitle';
    },
  },
  methods: {
    trans,
    ...mapActions({
      setPassengersCount: 'searchModule/setPassengersCount',
      setVehiclesCount: 'searchModule/setVehiclesCount',
      setPetsCount: 'searchModule/setPetsCount',
    }),
    onSimpleModalClose: function () {
      this.showTravelersModal = false;
      this.onBeforeModalClose();
      this.action = actions.nothing;
    },
    onBeforeModalOpen: function () {
      stopBodyScrolling(true);
      document.activeElement.blur();
    },
    onBeforeModalClose: function () {
      stopBodyScrolling(false);
    },
    onDateInputClick: function (index) {
      this.onBeforeModalOpen();
      this.activeDateIndex = index;
      this.action = actions.dates;
    },
    switchMode: function (searchMode) {
      // update global state
      if (searchMode === 'multi') {
        this.$store.dispatch('searchModule/setTripModeMulti');
      }
      // update global state
      if (searchMode === 'single') {
        this.$store.dispatch('searchModule/setTripModeSingle');
      }
      // emit change event
      emitter.$emit('onSearchParametersChange');
    },
    addDestination: function () {
      this.$store.dispatch('searchModule/addTrip');
      emitter.$emit('onSearchParametersChange');
      ga4Event('hopping_add_trip_clicked', {});
    },
    removeDestination: function () {
      this.$store.dispatch('searchModule/removeTrip');
      emitter.$emit('onSearchParametersChange');
    },
    onPassengersInputFocus: function () {
      this.showTravelersModal = true;
      this.onBeforeModalOpen();
      this.action = actions.passengers;
      ga4Event('ticket_control_options_opened', {});
    },
    onTravelersChange(passengers, vehicles, pets) {
      this.showTravelersModal = false;
      this.onBeforeModalClose();
      this.setPassengersCount(passengers);
      this.setVehiclesCount(vehicles);
      this.setPetsCount(pets);
      emitter.$emit('onSearchParametersChange');
      this.action = actions.nothing;
    },
    onPortInputFocus: function (index) {
      this.onBeforeModalOpen();
      this.port = index;
      this.action = actions.port;
      emitter.$emit('port-input-clicked', this.selectedPorts[this.port - 1]);
    },
    onPortSelectionChange: function (selection) {
      this.onBeforeModalClose();
      let port = this.locations.find((location) => location.LocationAbbr === selection);
      let portIndex = this.port - 1;
      this.$store.dispatch('searchModule/setSelectedPort', { port, portIndex });
      this.action = actions.nothing;
      emitter.$emit('onSearchParametersChange');
    },
    onComponentDestroy() {},
    selectedPortNames: function (index) {
      if (typeof this.selectedPorts[index] === 'undefined') {
        return '';
      }
      return this.selectedPorts[index] && this.selectedPorts[index].alias;
    },
    portholders: function (index) {
      switch (this.searchMode) {
        case 'multi':
          return message(`multiplaceholder${index}`);
        case 'single':
          return index === 1 ? message('depplaceholder') : message('arrplaceholder');
      }
    },
    switchPortsDirection: function () {
      if (!this.canSwitchDirections) return;

      this.flipDirection = !this.flipDirection;
      const originPort = this.selectedPorts[0];
      const destinationPort = this.selectedPorts[1];
      this.$store.dispatch('searchModule/setSelectedPort', { port: destinationPort, portIndex: 0 });
      this.$store.dispatch('searchModule/setSelectedPort', { port: originPort, portIndex: 1 });
      ga4Event('swap_ports_clicked', {});
    },
  },
};
</script>
