<template>
  <transition name="mobile-popup" appear>
    <div class="mobile-popup-box-mask">
      <div class="mobile-popup-box-wrapper">
        <div class="mobile-popup-box-container">
          <slot name="header"></slot>
          <div class="mobile-popup-box-container__body" :class="body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobilePopupBox',
  props: ['body'],
};
</script>